<template>
<div class="backtest"> 

    <div class="d-flex justify-start mb-6 text-h6"> 
      <v-icon left @click="$router.go(-1)"> mdi-arrow-left </v-icon> 
      {{ title }} 
    </div>

    <!-- 상단 버튼 -->
    <v-container fluid>
      <v-row
        no-gutters
      >
        <v-col  
          v-for="j in rows"
          :key="j"
          :class="rows === 1 ? 'd-flex justify-end' : 'd-flex justify-space-between'"
        >
          <v-btn     
            v-for="k in 4/rows"
            :key="k"
            v-show="btnShow[j === 2 && k === 1 ? j*k : j*k-1]"
            text
            color="primary"
            small 
            class="font-weight-bold"
            @click="btnFnc(j === 2 && k === 1 ? j*k : j*k-1)"
          >
            <v-icon left> 
              {{ icon[j === 2 && k === 1 ? j*k : j*k-1] }} 
            </v-icon>
            {{ btnName[j === 2 && k === 1 ? j*k : j*k-1] }}
          </v-btn>        
        </v-col>
      </v-row>
    </v-container>

      <v-card    
        v-if="overviewShow" 
        class="d-flex justify-end"
        flat 
      >                
        <v-dialog 
            v-model="ruleDialogShow"            
            width="auto" 
            :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <RuleDialog
            @hide="ruleDialogShow = false"
            @submit="put_my_quant_rules()"
          >    
          <template v-slot:title>
            <v-text-field 
              v-model="new_title"
              label="제목*" 
              hint="전문가 공식명 뒤에 '-'를 붙이고 적당한 제목을 입력하세요." 
              persistent-hint            
            > 
            {{ new_title }} 
            </v-text-field>             
          </template>
          <template v-slot:description>
            <v-textarea 
              v-model="description"
              label="퀀트전략" 
              hint="*매도, 매수, 리밸런싱 전략을 입력하세요. 퀀트전략에서도 입력 및 수정할 수 있습니다." 
              persistent-hint        
              outlined
              auto-grow    
            > 
            {{ description }} 
            </v-textarea>             
          </template>
          </RuleDialog>
        </v-dialog>
        <v-dialog 
          v-model="qsDialogShow" 
          max-width="1200"
          overlay-color="white"
          overlay-opacity="1"
          retain-focus
        >
          <v-progress-circular
            v-if="progressWheel"
            indeterminate
            color="primary"
          ></v-progress-circular>

          <span 
            v-html="HTMLcontent"
          >
          </span>            
          <v-card-actions class="d-flex justify-end pa-6">
            <v-btn 
              depressed 
              outlined
              color="primary"
              @click="qsDialogShow=false"
            > 
            닫 기
            </v-btn>  
          </v-card-actions>
        </v-dialog>          
      </v-card>

      <br>
      <v-card 
        v-if="overviewShow"
        class="d-flex justify-space-around pa-6"
      >

      <v-row>
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-end">
            <span> 누적 수익율(%)
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="pr-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
                </template>
                누적수익율은 연복리 수익율로서 투자원금에 수익금을 계속 재투자 함으로서 수익율 최대화가 가능
              </v-tooltip>
              <v-chip :color="getColor(cum_yield)" dark >
                {{ cum_yield }}
              </v-chip>
            </span> 
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-end">
            <span> 평균 수익율(%)
              <v-chip :color="getColor(avg_yield)" dark >
                {{ avg_yield }}
              </v-chip>
            </span>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-end">  
            <span> 수익발생월(%)
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="pr-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
                </template>
                총 테스트 기간 중 수익이 발생한 월의 비율
              </v-tooltip>
              <v-chip :color="getColor(yield_plus)" dark >
                {{ yield_plus }}
              </v-chip>
            </span> 
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-end">
            <span> 표준편차
              <v-chip :color="getColor(std)" dark >
                  {{ std }}
              </v-chip>
            </span> 
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-end">
            <span> MDD
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="pr-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>
                  최대낙폭(MDD) = (최저점/전고점) - 1 특정 투자 기간 중 겪을 수 있는 가장 큰 손실을 의미
              </v-tooltip>
              <v-chip :color="getColor(mdd)" dark >
                {{ mdd}}
              </v-chip>
            </span>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-end">
            <span> 샤프지수
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="pr-2" v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>
                  샤프지수 = (연복리수익율 - 무위험이자율) / 변동성
              </v-tooltip>
              <v-chip :color="getColor(sharpe)" dark >
                  {{ sharpe }}
              </v-chip>
            </span>  
          </v-col>
      </v-row>  
    </v-card>
    <br>

    <!-- Progress Line -->
    <v-container v-if="loadTable" style="height: 200px;" >
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          백테스트 중입니다.
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>

    <v-card>
      <v-data-table
        v-if="tableShow"
        :headers="month_yield_headers"
        :items="month_yield_lists"
        :items-per-page="10"
        class="elevation-1"
        :hide-default-footer=true
      >
        <template v-slot:top>
          <v-switch
            v-if="overviewShow"
            v-model="dateOrder"
            label="최신순"
            class="pa-3"
            dense
            @change="changeOrder"
          ></v-switch>
        </template>        
      </v-data-table>    
    </v-card>
 
    <br>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <!-- Yield Chart -->
        <v-card v-if = "overviewShow">
          <template>
              <div class="container">
                  <line-chart
                      v-if="chartLoaded"
                      :chartData="datacollection"
                      :options="chart_options"
                  />
              </div> 
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <!-- MDD Chart -->
        <v-card v-if = "overviewShow">
          <template>
              <div class="container">
                  <line-chart
                      v-if="mdd_chartLoaded"
                      :chartData="mdd_datacollection"
                      :options="mdd_chart_options"
                  />
              </div> 
          </template>
        </v-card>
      </v-col>
    </v-row>
    
    <!-- 알림 메세지 -->
    <v-snackbar
      v-model="snackbar"
      :vertical="vertical"
      :color="snackColor"          
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';
import LineChart from '../components/LineChart.js';
import RuleDialog from '../components/RuleDialog.vue';

export default {
    name: "Backtest",
    components: { RuleDialog, LineChart },
    data() {
        return {
          btnName: [
          '',
          '퀀트전략에 추가',
          '세부 거래 내역',
          '세부 퀀트 통계',
          ],
          icon: [
            '',
            'mdi-plus',
            'mdi-playlist-check',
            'mdi-numeric',
          ],

          btnShow: [false, false, true, true],
          
          tableShow: false,
          loadTable: false,     
          loadedChart: false, 
          ruleDialogShow: false,
          addBtnShow: false,
          overviewShow: false,
          helpDialogShow: false,
          qsDialogShow: false,
          progressWheel: true,
          fab: false,
          dateOrder: false,

          new_title: null,
          description: null,
          helpTitle: null,
          helpText: null,
          backtestResult: null,
          cum_yield: null,
          avg_yield: null,
          yield_plus: null,
          std: null,
          mdd: null,
          sharpe: null,
          month_yield_headers: [],
          month_yield_lists: [],
          month_mdd_lists: [],
          datacollection: null,
          chartLoaded: null,
          mdd_datacollection: null,
          mdd_chartLoaded: null,
          HTMLcontent: null,
          
          chart_options: {
            title: {
              display: true,
              text: '월 수익율',
            },            
            scales: {
              xAxes: [{
                  gridLines: { drawOnChartArea: false }
              }],
              yAxes: [{
                  gridLines: { drawOnChartArea: true }
              }]
            },
            tooltips: {
              axis: 'xy', 
              mode: 'nearest',
              intersect: false
            },
          },
          mdd_chart_options: {
            title: {
              display: true,
              text: 'MDD',
            },     
            scales: {
              xAxes: [{
                  gridLines: { drawOnChartArea: false }
              }],
              yAxes: [{
                  gridLines: { drawOnChartArea: true }
              }]
            },       
            legend: {
              display: false,
            },
            tooltips: {
              axis: 'xy', 
              mode: 'nearest',
              intersect: false
            },
          },
          
          snackbar: false,
          vertical: true,
          snackColor: null,
          snackText: null,

        }
    },

    computed: {
      ...mapState(['isLogin','debug', 'user', 'market', 'market_cap', 'sector', 'filters', 'rules', 'title',
        'quant_id', 'amount', 'stocks', 'time', 'term', 'route', 'qs_country', 'qs_month', 'qs_data', 'qs_benchmark']),
      
      rows() {
        const { xs } = this.$vuetify.breakpoint
        return xs ? 2 : 1
      },  
    },

    created () {     
        if (this.isLogin) this.backtest();
        else this.$router.push('/');
    },    

    methods: {      
        ...mapMutations(['setTestTerm', 'setQS']),

        btnFnc(idx) {
          if ( idx === 1 ) this.ruleDialogShow=true;
          else if ( idx === 2 ) this.backtest_detail();
          else if ( idx === 3 ) this.showQuantStats();
        },
        
        backtest_detail() {
          this.$router.push('/backtest_detail')
        },

        backtest() {
            this.tableShow = false;  
            this.loadTable = true;     
            this.loadedChart = false;            
            this.new_title = this.title;
            var backtestQuaryData = {}; 

            if (this.route === 'expert') {
                this.btnShow[1] = true;
                backtestQuaryData = { 'quant_id': this.quant_id };
            } else if (this.route === 'my') {
                backtestQuaryData = { 'route': this.route, 'user': this.user, 'quant_id': this.quant_id, 
                  'market': this.market, 'market_cap': this.market_cap, 'sector': this.sector, 
                  'amount': this.amount, 'stocks': this.stocks, 'time': this.time, 'term': this.term };
            } else if (this.route === 'form') {
                this.btnShow[1] = true;
                backtestQuaryData = { 'route': this.route, 'user': this.user, 'quant_id': this.quant_id,
                  'market': this.market, 'market_cap': this.market_cap, 'sector': this.sector,
                  'filters': this.filters, 'rules': this.rules,  
                  'amount': this.amount, 'stocks': this.stocks, 'time': this.time, 'term': this.term };
            }
            
            var vm = this;    
            
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
            axios.post('/api/quant/backtest/'+this.route+'/', backtestQuaryData, {headers})
                .then(function(res) {
                    var backtestResult = {};
                    backtestResult = res.data;
                    vm.cum_yield = backtestResult['cum_yield'];
                    vm.avg_yield = backtestResult['avg_yield'];
                    vm.yield_plus = backtestResult['yield_plus'];
                    vm.std = backtestResult['std'];
                    vm.mdd = backtestResult['mdd'];
                    vm.sharpe = backtestResult['sharpe'];
                    vm.month_yield_headers = backtestResult['month_yield_headers'];
                    
                    // backtest_detail에서 사용할 테스트기간(년/월) 배열 설정
                    var monthList = [];
                    var quantMonthList = []
                    vm.month_yield_headers.forEach(element => {
                      monthList.push(element['text']);                       
                      quantMonthList.push(element['text'].substring(0,4)+element['text'].substring(5,7));
                    });
                    monthList.shift();
                    quantMonthList.shift();
                    vm.setTestTerm({'test_term': monthList});

                    vm.month_yield_lists = backtestResult['month_yield_lists'];
                    vm.month_mdd_lists = backtestResult['mdd_list'];
                    
                    // yield chart data
                    var v_borderColor = ['#BAFB04', '#16F40A', '#05BDF8', '#0B37FA', '#F4A7B9', '#F90E45'];
                    var v_labels = [];
                    var v_datasets = [];
                    vm.month_yield_headers.forEach(function(item){
                        v_labels.push(item['text']);  
                    });
                    v_labels.shift();
                    
                    var i = 0;
                    vm.month_yield_lists.forEach(function(item){
                        var data_tmp = Object.values(item);
                        data_tmp.splice(0, 1);
                        v_datasets.push({ data: data_tmp, label: item['0'], borderColor: v_borderColor[i], fill: false, radius: 0 });
                        i++;  
                    });
                    vm.datacollection = { labels: v_labels, datasets: v_datasets};
                    vm.chartLoaded = true;

                    // quant statstic
                    vm.setQS({'qs_country': 'korea', 'qs_month': backtestResult['month_list'],
                      'qs_data': backtestResult['yield_list'], 'qs_benchmark': backtestResult['kospi_list']});

                    // mdd chart data
                    var v_mdd_labels = [];
                    var v_mdd_datasets = [];
                    vm.month_yield_headers.forEach(function(item){
                        v_mdd_labels.push(item['text']);   
                    });
                    v_mdd_labels.shift();
                    
                    v_mdd_datasets.push({ label: 'MDD', data: vm.month_mdd_lists, borderColor: 'red', 
                      backgroundColor: '#FFCDD2', fill: true, radius: 0 });
                    vm.mdd_datacollection = { labels: v_mdd_labels, datasets: v_mdd_datasets};
                    vm.mdd_chartLoaded = true;

                    vm.loadTable = false;
                    vm.overviewShow = true;  
                    vm.tableShow = true;                           
                })
                .catch(function (err) {                        
                        vm.snackbar = true;
                        vm.snackColor = 'error';
                        vm.snackText = err;
                        vm.loadTable = false;
                        vm.chartLoaded = false;
                });  
        },

        showQuantStats() {
          this.qsDialogShow = true;
          var vm = this;    
          const req_data = {'qs_title': this.title, 'qs_country': this.qs_country, 'qs_month': this.qs_month, 
            'qs_data': this.qs_data, 'qs_benchmark': this.qs_benchmark};

          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};     
          axios.post('/api/etf/backtest/show_stats/', req_data, {headers})
            .then(function(res) {
              vm.HTMLcontent = res.data['qs_html'];
              vm.progressWheel = false;
            })
            .catch(function (err) {
            });  
        },
        
        sleep(t){
          return new Promise(resolve=>setTimeout(resolve,t));
        },

        put_my_quant_rules () {
          const saveQuantRules = { 'user': this.user, 'title': this.new_title, 'market': this.market, 'market_cap': this.market_cap, 
            'sector': this.sector, 'filter_items': this.filters, 'search_items': this.rules, 'description': this.description };
          
          var vm = this;
          
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
          axios.post('/api/quant/save/', saveQuantRules, {headers})
            .then(function(res) {
                vm.$router.push('/rule');
            })
            .catch(function (err) { 
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;
            });
        },

        onScroll (e) {
          if (typeof window === 'undefined') return
          const top = window.pageYOffset || e.target.scrollTop || 0
          this.fab = top > 20
        },


        getColor (value) {
            if (value < 0 ) return 'red';
            else return 'green';
        },    

        changeOrder () {
          const category = this.month_yield_headers[0] // 구분 해더 유지
          this.month_yield_headers.reverse();  
          this.month_yield_headers.pop();             // 구분 해더 삭제
          this.month_yield_headers.unshift(category); // 구분 해더 맨 앞에 추가
        },
        
        
    },
    
}
</script>

<style>
.backtest{
   font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
   margin-top: 10px;
}
.red {
  color: red;
}
.stackSheet {
  position: relative;
}
.stackSpark {
  position: absolute;
  top: 0;
  left: 0;
}
#left{width:620px;margin-right:18px;margin-top:18px;float:left}
#right{width:320px;margin-top:18px;float:right;}
</style>

