<template>
  <div>
    <v-card>
        <v-card-title>
        <span class="text-h5">나의 퀀트전략에 추가</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <v-row>
            <v-col cols="12">               
              <slot name="title">
                {{ title }}
              </slot>
            </v-col>
            <v-col cols="12">               
              <slot name="description">
                {{ description }}
              </slot>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4" class="d-flex justify-start">
                <span>시장: {{market}} </span>            
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4" class="d-flex justify-start">
                <span>시총: {{market_cap}} % 이하 </span>            
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4" class="d-flex justify-start">
                업종: 
                <span v-for="(item, index) in sector" :key="index">  
                  <v-chip class="ma-2" small> {{ item }} </v-chip>    
                 </span>         
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex justify-start">
              지표필터:
              <span v-for="(item, index) in filters" :key="index">  
                  <v-chip class="ma-2" small> {{ item.text }} {{ item.min }} - {{ item.max }} </v-chip>    
              </span>
            </v-col>  
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex justify-start">
              검색지표:
              <span v-for="(item, index) in rules" :key="index">  
                  <v-chip class="ma-2" small> {{ item.text }} </v-chip>    
              </span>
            </v-col>                                                    
            </v-row>
        </v-container>
        <small>*필수 입력 항목</small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pa-6">
          <v-btn depressed outlined @click="$emit('hide')"> 닫 기 </v-btn>
          <v-btn color="primary" @click="$emit('submit')" > 저 장 </v-btn>
        </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import axios from 'axios';

  export default {
      name: "RuleDialog",
      data () {
        return {
          // market: null,
          // market_cap: null,
          // sector: [],
          // filters: [],
          // rules: [],
        }
      },
      computed: {
          ...mapState(['debug', 'title', 'description', 'market', 'market_cap', 'sector', 'filters', 'rules', 'quant_id']) 
      },
      created() {
        // this.getRules();
      },

      methods: {
        ...mapMutations(['setMarket', 'setRule', 'setFilter']),

        getRules() {
          this.queryData = {user: 'expert'};
          const jwt = localStorage.getItem('jwt');
          const headers = {"Authorization": "jwt "+ jwt};
          var vm = this; 
          axios.post('/api/quant/rule/'+this.quant_id+'/', this.queryData, {headers})
              .then(function(res) {
                  vm.market = res.data['market'];
                  vm.market_cap = res.data['market_cap'];
                  vm.sector = res.data['sector'];
                  vm.filters = res.data['filters'];
                  vm.rules = res.data['rules']; 
                  vm.description = res.data['description'];

                  var quant_text = {};
                  quant_text['market'] = vm.market; 
                  quant_text['market_cap'] = vm.market_cap; 
                  quant_text['sector'] = vm.sector; 
                  quant_text['description'] = vm.description; 
                  vm.setMarket(quant_text);
                  vm.setFilter({'filters': vm.filters});
                  vm.setRule({'rules': vm.rules});  
              })
              .catch(function (err) {
                  vm.loadTable = false;    
                  vm.snackbar = true;
                  vm.snackColor = 'error';
                  vm.snackText = err;            
              });                
        },
      }
  }
</script>

<style>
.quant-rule-save{
   font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
   margin-top: 140px;
}
</style>
